// colors
$blue-primary: #1b1622;
$blue-dark: #110e17;
$blue-darker: #0e0b12;
$yellow-primary: #dcc81b;
$yellow-dark: #d4c119;
$yellow-darker: #cebb16;
$white: #fff;
$grey: #dedede;
$dark-grey: #515151;
$red-primary: #ff3737;
$red-dark: #bd0a0a;
$red-darker: #660404;
$text-dark: #3d3d3d;

// fonts
$font: (
    "thin": "Mont Thin",
    "hairline": "Mont Hairline",
    "light": "Mont Light",
    "extralight": "Mont ExtraLight",
    "book": "Mont Book",
    "regular": "Mont Regular",
    "semibold": "Mont SemiBold",
    "bold": "Mont Bold",
    "black": "Mont Black",
    "heavy": "Mont Heavy",
);

$full-width: 100%;

// paddings & margins
$padding: 25px;