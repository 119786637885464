@import "./fonts";
@import "./variables";
@import "./functions";
@import "./resets";
@import './scrollbar';
@import "./radiobutton";

.loginPage{
    width: $full-width;
    height: 100vh;
    float: left;
    background-image: url('../../public/images/primary-texture-bg.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginPageContent{
    width: 550px;
    float: left;
}

.loginPageContentLogo{
    width: $full-width;
    float: left;
    display: flex;
    justify-content: center;
}

.loginPageContentLogoInner{
    width: 170px;
    float: left;
}

.loginPageContentLogoImg{
    width: $full-width;
    float: left;
}

.loginPageContentForm{
    width: $full-width;
    float: left;
    border: 2px dashed $dark-grey;
    border-radius: 20px;
    padding: 30px;
    margin-top: 40px;
}

.loginPageContentFormHeading{
    width: $full-width;
    float: left;
    display: flex;
    justify-content: center;
    padding-bottom: 22px;
    border-bottom: 2px dashed $dark-grey;
}

.loginPageContentFormHeadingTxt{
    float: left;
    color: $white;
    font-size: 2.2em;
    text-transform: uppercase;
    font-family: font("regular");
}

.loginPageContentFormMain{
    width: $full-width;
    float: left;
    margin-top: 30px;
}

.loginPageContentFormStep{
    width: $full-width;
    float: left;
    margin-bottom: 25px;
}

.loginPageContentFormStep:last-child{
    margin: 0px;
}

.loginPageContentFormStepLable{
    width: $full-width;
    float: left;
}

.loginPageContentFormStepLableTxt{
    float: left;
    text-transform: capitalize;
    font-size: 0.9em;
    font-family: font("regular");
    color: $yellow-primary;
}

.loginPageContentFormStepInput{
    width: $full-width;
    float: left;
    margin-top: 10px;
}

.loginPageContentFormStepInputTxt{
    width: $full-width;
    float: left;
    padding: 15px 20px;
    border: 2px dashed $dark-grey;
    border-radius: 10px;
    background: none;
    color: $white;
    font-size: 1em;
}

.loginPageContentFormStepSubmit{
    width: $full-width;
    float: left;
    padding: 10px 0px;
    background-color: $yellow-primary;
    border-radius: 5px;
    font-size: 1.1em;
    font-family: font('semibold');
    text-transform: uppercase;
    cursor: pointer;
}

.loginPageContentFormStepSubmit:hover{
    background-color: $yellow-darker;
}

.footer{
    width: $full-width;
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: $blue-darker;
    box-shadow: inset 0px 3px 5px rgba(0, 0, 0, 0.4);
}

.footerCopyright{
    float: left;
    color: $white;
    font-size: 0.85em;
}

.footerPoweredBy{
    float: left;
    color: $white;
    font-size: 0.85em;
}

.footerPoweredBy .footerPoweredByHighlight{
    color: $yellow-primary;
    cursor: pointer;
}

.posPage{
    width: $full-width;
    height: 100vh;
    float: left;
    background-color: $blue-primary;
    display: flex;
    flex-direction: row;
}

.posPageLeftNav{
    width: 135px;
    height: 100vh;
    float: left;
    background-color: $blue-dark;
    display: flex;
    flex-direction: column;
}

.posPageLeftNavLogo{
    width: $full-width;
    float: left;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.posPageLeftNavLogoMain{
    width: 80px;
    float: left;
}

.posPageLeftNavLogoImg{
    width: $full-width;
    float: left;
}

.posPageLeftNavMain{
    width: $full-width;
    float: left;
    flex: 1;
    display: flex;
    align-items: center;
}

.posPageLeftNavMainInner{
    width: $full-width;
    float: left;
}

.posPageLeftNavItem{
    width: $full-width;
    float: left;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    cursor: pointer;
}

.posPageLeftNavItem:last-child{
    margin: 0px;
}

.posPageLeftNavItemIcon{
    height: 54px;
    width: 54px;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.posPageLeftNavItemIcon:hover {
    background-color: $yellow-primary;
    border-radius: 5px;
}

.posPageLeftNavItemIcon:hover .posPageLeftNavItemIco {
    color: $blue-darker;
}

.posPageLeftNavItemIcon.selected{
    background-color: $yellow-primary;
    border-radius: 5px;
}

.posPageLeftNavItemIcon.selected .posPageLeftNavItemIco{
    color: $blue-darker;
}

.posPageLeftNavItemIco{
    color: $white;
    float: left;
    font-size: 1.6em;
}

.posPageContent{
    float: left;
    position: relative;
    padding: 0px 40px;
    height: $full-width;
    flex: 1;
    width: calc($full-width - 130px);
}

.posPageContent.hasSidebar{
    padding: 0px 500px 0px 40px;
}

.posPageContentMain{
    width: $full-width;
    float: left;
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.posPageContentHeader{
    width: $full-width;
    float: left;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px dashed $dark-grey;
}

.posPageContentHeaderPageDate{
    float: left;
    display: flex;
    flex-direction: column;
}

.posPageContentHeaderPageTxt{
    float: left;
    font-family: font("regular");
    font-size: 2em;
    color: $white;
    text-transform: capitalize;
}

.posPageContentHeaderDateTxt{
    float: left;
    font-family: font("regular");
    text-transform: capitalize;
    color: $yellow-primary;
    margin-top: 10px;
    font-size: 0.85em;
}

.posPageContentHeaderProfileLogout{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.posPageContentHeaderProfile{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.posPageContentHeaderProfileAvatar{
    width: 55px;
    height: 55px;
    float: left;
    overflow: hidden;
    border-radius: 55px;
}

.posPageContentHeaderProfileAvatarImg{
    width: $full-width;
    height: $full-width;
    float: left;
}

.posPageContentHeaderProfileDetails{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.posPageContentHeaderProfileDetailsNameTxt{
    float: left;
    font-family: font("regular");
    color: $white;
    font-size: 1.4em;
    text-transform: capitalize;
}

.posPageContentHeaderProfileDetailsEmailTxt{
    float: left;
    font-family: font("regular");
    color: $yellow-primary;
    font-size: 0.9em;
}

.posPageContentHeaderLogout{
    display: flex;
    justify-content: center;
    align-content: center;
}

.posPageContentHeaderLogoutMain{
    width: 44px;
    height: 44px;
    border-radius: 10px;
    background-color: $yellow-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.posPageContentHeaderLogoutMain:hover{
    background-color: $yellow-darker;
}

.posPageContentHeaderLogoutIco{
    color: $blue-primary;
    font-size: 1.4em;
}

.posPageContentContainer{
    width: $full-width;
    height: calc(100% - 120px);
    float: left;
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
}

.posPageContentContainerOrder{
    width: $full-width;
    height: calc(100% - 120px);
    float: left;
    padding: 30px 0px 30px;
    display: flex;
    flex-direction: column;
}

.posPageContentContainerCategories{
    width: $full-width;
    float: left;
    position: relative;
    padding-right: 100px;
}

.posPageContentContainerCategoriesMain{
    width: $full-width;
    float: left;
}

.posPageContentContainerCategory{
    float: left;
    padding-right: 20px;
}

.posPageContentContainerCategoryMain{
    width: $full-width;
    float: left;
    padding: 0px 30px;
    border-radius: 50px;
    background: none;
    border: 1px dashed $dark-grey;
    margin-right: 20px;
    height: 45px;
    display: flex !important;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.posPageContentContainerCategoryMain:hover{
    background: $yellow-primary;
    border: 1px solid $yellow-primary;
}

.posPageContentContainerCategoryMain.selected{
    background: $yellow-primary;
    border: 1px solid $yellow-primary;
}

.posPageContentContainerCategoryIco{
    color: $white;
    font-size: 1.2em;
}
.posPageContentContainerCategoryIco.fa{
    font-size: 1em !important;
}

.posPageContentContainerCategoryMain:hover .posPageContentContainerCategoryIco{
    color: $blue-dark;
}

.posPageContentContainerCategoryMain.selected .posPageContentContainerCategoryIco{
    color: $blue-dark;
}

.posPageContentContainerCategoryTxt{
    float: left;
    color: $white;
    font-size: 0.9em;
    font-family: font("semibold");
    text-transform: capitalize;
}

.posPageContentContainerCategoryMain:hover .posPageContentContainerCategoryTxt{
    color: $blue-dark;
}

.posPageContentContainerCategoryMain.selected .posPageContentContainerCategoryTxt{
    color: $blue-dark;
}

.posPageContentContainerCategoriesArrows{
    width: 100px;
    float: left;
    position: absolute;
    right: 0px;
    top: 0px;
}

.posPageContentContainerCategoriesArrowSingle{
    width: 45px;
    height: 45px;
    border: 1px dashed $dark-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    border-radius: 45px;
    margin-right: 10px;
    cursor: pointer;
}

.posPageContentContainerCategoriesArrowSingle:last-child{
    margin: 0px;
}

.posPageContentContainerCategoriesArrowSingleIco{
    color: $white;
    font-size: 1.1em;
    float: left;
}

.posPageContentContainerCategoriesArrowSingle:hover {
    border: 2px solid $yellow-primary;
}

.posPageContentContainerCategoriesArrowSingle:hover .posPageContentContainerCategoriesArrowSingleIco {
    color: $yellow-primary;
}

.posPageContentContainerSearch{
    width: $full-width;
    float: left;
    margin-top: 30px;
}

.posPageContentContainerSearchInput{
    width: $full-width;
    float: left;
    border-radius: 30px;
    height: 50px;
    padding: 0px 30px;
    background-color: $blue-dark;
    box-shadow: inset 0px 5px 5px rgba(0, 0, 0, 0.8);
    color: $white;
    text-transform: capitalize;
}

.posPageContentContainerMenu{
    width: $full-width;
    overflow-x: auto;
    margin-top: 30px;
}

.posPageContentContainerOrderInner{
    width: $full-width;
    overflow-x: auto;
}

.posPageContentContainerMenuItem{
    width: 33.333%;
    float: left;
    padding: 0px 25px 25px 0px;
}

.posPageContentContainerMenuItem:nth-child(3n){
    padding: 0px 0px 25px 0px;
}

.posPageContentContainerMenuItemInner{
    width: $full-width;
    float: left;
    border-radius: 10px;
    border: 2px dashed $dark-grey;
    height: 120px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 0px 20px;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.posPageContentContainerMenuItemInner:hover{
    border: 2px solid $yellow-primary;
}

.posPageContentContainerMenuItemAvatar{
    width: 85px;
    height: 85px;
    float: left;
}

.posPageContentContainerMenuItemAvatarImg{
    width: $full-width;
    float: left;
}

.posPageContentContainerMenuItemDetails{
    float: left;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.posPageContentContainerMenuItemNameTxt{
    float: left;
    font-family: font("bold");
    font-size: 1.3em;
    color: $yellow-primary;
    text-transform: capitalize;
    line-height: 1.4em;
}

.posPageContentContainerMenuItemCost{
    float: left;
    display: flex;
    flex-direction: row;
}

.posPageContentContainerMenuItemCostRupee{
    float: left;
    margin-top: 7px;
}

.posPageContentContainerMenuItemCostRupeeIco{
    color: $white;
    font-size: 0.7em;
    float: left;
}

.posPageContentContainerMenuItemCostTxt{
    float: left;
    margin-left: 10px;
    font-size: 1.2em;
    color: $white;
    font-family: font("semibold");
}

.posPageContentContainerMenuItemCart{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: $yellow-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 5px 0px;
    cursor: pointer;
}

.posPageContentContainerMenuItemCart:hover{
    background-color: $yellow-darker;
}

.posPageSidebar{
    width: 460px;
    float: left;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    background: $blue-dark;
}

.posPageSidebarInner{
    width: 100%;
    height: 100%;
    float: left;
    // padding: 0px 35px;
    position: relative;
}

.posPageSidebarHeading{
    width: $full-width;
    float: left;
    height: 70px;
    display: flex;
    align-items: flex-end;
    padding: 0px 35px;
}

.posPageSidebarHeadingTxt{
    color: $white;
    text-transform: capitalize;
    font-family: font("semibold");
    font-size: 1.8em;
}

.posPageSidebarCustomerDetails{
    width: $full-width;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding: 0px 35px;
}

.posPageSidebarCustomerAvatarName{
    float: left;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.posPageSidebarCustomerAvatar{
    width: 20px;
    height: 20px;
    border-radius: 20px;
    float: left;
    overflow: hidden;
}

.posPageSidebarCustomerAvatarImg{
    width: 20px;
    height: 20px;
    float: left;
}

.posPageSidebarCustomerName{
    float: left;
    color: $white;
    font-family: font("regular");
    text-transform: capitalize;
    font-size: 0.85em;
}

.posPageSidebarCustomerOrderNo{
    float: left;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.posPageSidebarCustomerOrderNoLable{
    float: left;
}

.posPageSidebarCustomerOrderNoLableTxt{
    float: left;
    color: $white;
    font-family: font("regular");
    font-size: 0.85em;
    text-transform: capitalize;
}

.posPageSidebarCustomerOrderNoVal{
    float: left;
}

.posPageSidebarCustomerOrderNoValTxt{
    float: left;
    color: $yellow-primary;
    font-family: font("regular");
    font-size: 0.85em;
    text-transform: capitalize;
}

.posPageSidebarOrderTypes{
    width: $full-width;
    float: left;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    margin-top: 20px;
    padding: 0px 35px;
}

.posPageSidebarOrderType{
    height: 35px;
    float: left;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    border: 2px dashed $dark-grey;
    border-radius: 30px;
    cursor: pointer;
}

.posPageSidebarOrderType.selected{
    background-color: $yellow-primary;
    border: 2px solid $yellow-primary;
}

.posPageSidebarOrderType.selected .posPageSidebarOrderTypeLable{
    color: $blue-dark;
}

.posPageSidebarOrderType:hover{
    background-color: $yellow-primary;
    border: 2px solid $yellow-primary;
}

.posPageSidebarOrderType:hover .posPageSidebarOrderTypeLable{
    color: $blue-dark;
}

.posPageSidebarOrderTypeLable{
    font-size: 0.85em;
    float: left;
    color: $white;
    text-transform: uppercase;
    font-family: font("semibold");
}

.posPageSidebarCurrentOrder{
    width: $full-width;
    float: left;
    margin-top: 25px;
    height: calc(100% - 180px);
    padding: 42px 0px 25px;
    position: relative;
}

.posPageSidebarCurrentOrder.hasPadding{
    padding: 42px 0px 350px;
}

.posPageSidebarCurrentOrderHeadingButton{
    float: left;
    width: $full-width;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0px;
    top: 0px;
    background: $blue-dark;
    padding: 0px 35px;
}

.posPageSidebarCurrentOrderHeading{
    float: left;
}

.posPageSidebarCurrentOrderHeadingTxt{
    float: left;
    color: $white;
    font-size: 1.4em;
    text-transform: capitalize;
}

.posPageSidebarCurrentOrderButton{
    float: left;
}

.posPageSidebarCurrentOrderButtonTxt{
    margin-top: 5px;
    float: left;
    color: $yellow-primary;
    text-transform: uppercase;
    font-size: 0.85em;
    padding-bottom: 2px;
    border-bottom: 1px solid $yellow-primary;
    cursor: pointer;
}

.posPageSidebarCurrentOrderButtonTxt:hover{
    color: $white;
    border-bottom: 1px solid $white;
}

.posPageSidebarCurrentOrderItems{
    width: $full-width;
    float: left;
    padding: 0px 35px;
    overflow-x: auto;
    height: 100%;
}

.posPageSidebarCurrentOrderItem{
    width: $full-width;
    float: left;
    padding-bottom: 20px;
    border-bottom: 2px dashed $dark-grey;
    margin-bottom: 20px;
}

.posPageSidebarCurrentOrderItem:last-child{
    padding: 0px;
    border: 0px;
    margin: 0px;
}

.posPageSidebarCurrentOrderItemInfoQty{
    width: $full-width;
    float: left;
    display: flex;
    flex-direction: column;
    height: 70px;
}

.posPageSidebarCurrentOrderItemInfo{
    width: $full-width;
    float: left;
    padding: 0px 100px 0px 70px;
    display: flex;
    align-items: center;
    position: relative;
    height: $full-width;
}

.posPageSidebarCurrentOrderItemInfoAvatar{
    width: 70px;
    height: 70px;
    border: 2px dashed $dark-grey;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 70px;
}

.posPageSidebarCurrentOrderItemInfoAvatarMain{
    width: 50px;
    height: 50px;
    float: left;
}

.posPageSidebarCurrentOrderItemInfoAvatarImg{
    width: $full-width;
    float: left;
}

.posPageSidebarCurrentOrderItemInfoDetails{
    padding: 0px 15px;
    float: left;
    width: $full-width;
}

.posPageSidebarCurrentOrderItemInfoNameTxt{
    width: $full-width;
    float: left;
    text-transform: capitalize;
    color: $yellow-primary;
    font-family: font("bold");
    font-size: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.posPageSidebarCurrentOrderItemInfoSizeBaseTxt{
    float: left;
    margin-top: 10px;
    color: $white;
    font-size: 0.85em;
    text-transform: capitalize;
}

.posPageSidebarCurrentOrderItemInfoCatPrice{
    float: left;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    margin-top: 7px;
}

.posPageSidebarCurrentOrderItemInfoCat{
    float: left;
    color: $white;
    font-size: 0.85em;
    text-transform: capitalize;
}

.posPageSidebarCurrentOrderItemInfoSeprator{
    float: left;
    font-size: 0.4em;
    color: $yellow-primary;
}

.posPageSidebarCurrentOrderItemInfoPriceDets{
    float: left;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.posPageSidebarCurrentOrderItemInfoPriceDetsQtyTxt{
    float: left;
    color: $white;
    font-size: 0.85em;
    text-transform: capitalize;
}

.posPageSidebarCurrentOrderItemInfoPriceDetsMltIco{
    float: left;
    color: $white;
    font-size: 0.7em;
    text-transform: capitalize;
    margin-top: 1px;
}

.posPageSidebarCurrentOrderItemInfoPriceDetsMain{
    float: left;
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.posPageSidebarCurrentOrderItemInfoPriceDetsRupeeIco{
    float: left;
    color: $white;
    font-size: 0.6em;
    margin-top: 2px;
}

.posPageSidebarCurrentOrderItemInfoPriceDetsRupeeTxt{
    float: left;
    color: $white;
    font-size: 0.85em;
    text-transform: capitalize;
}

.posPageSidebarCurrentOrderItemQty{
    width: 100px;
    height: 70px;
    float: left;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 0px;
}

.posPageSidebarCurrentOrderItemQtyInner{
    width: 100px;
    height: 40px;
    border-radius: 40px;
    background-color: $yellow-primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.posPageSidebarCurrentOrderItemQtyButton{
    width: 30px;
    height: 30px;
    float: left;
    background-color: $blue-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 30px;
}

.posPageSidebarCurrentOrderItemQtyButtonIco{
    color: $white;
    float: left;
    font-size: 1.1em;
}

.posPageSidebarCurrentOrderItemInstrActions{
    width: $full-width;
    float: left;
    margin-top: 15px;
    padding-right: 110px;
    position: relative;
}

.posPageSidebarCurrentOrderItemInstr{
    width: $full-width;
    float: left;
    position: relative;
}

.posPageSidebarCurrentOrderItemInstrTxt{
    width: $full-width;
    float: left;
    height: 40px;
    border-radius: 40px;
    border: 2px dashed $dark-grey;
    background: none;
    padding: 0px 15px;
    color: $white;
    overflow: hidden;
}

.posPageSidebarCurrentOrderItemInstrSave{
    width: 36px;
    height: 36px;
    float: left;
    position: absolute;
    right: 2px;
    top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blue-dark;
    border-radius: 40px;
}

.posPageSidebarCurrentOrderItemInstrSaveInner{
    width: 30px;
    height: 30px;
    float: left;
    border-radius: 30px;
    background-color: $yellow-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.posPageSidebarCurrentOrderItemInstrSaveInner{
    background-color: $yellow-darker;
}

.posPageSidebarCurrentOrderItemActions{
    width: 90px;
    float: left;
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 0px;
    top: 0px;
}

.posPageSidebarCurrentOrderItemActionSingle{
    width: 40px;
    height: 40px;
    float: left;
    background-color: $yellow-primary;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.posPageSidebarCurrentOrderItemActionSingle:hover{
    background-color: $yellow-darker;
}

.posPageSidebarCurrentOrderItemActionSingleIco{
    color: $blue-dark;
    font-size: 1.1em;
    float: left;
}

.posPageSidebarOrderPricingActions{
    width: $full-width;
    float: left;
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 20px;
}

.posPageSidebarOrderPricing{
    width: $full-width;
    float: left;
    background-color: $blue-primary;
    padding: 20px;
    border-radius: 10px;
}

.posPageSidebarOrderPricingStep{
    width: $full-width;
    float: left;
    margin-bottom: 18px;
}

.posPageSidebarOrderPricingStep:last-child{
    margin: 0px;
}

.posPageSidebarOrderPricingStepLable{
    width: 55%;
    float: left;
    position: relative;
}

.posPageSidebarOrderPricingStepLableTxt{
    float: left;
    color: $white;
    font-size: 1em;
    text-transform: capitalize;
}

.posPageSidebarOrderPricingStepSeperator{
    position: absolute;
    right: 0px;
    bottom: 2px;
    float: left;
}

.posPageSidebarOrderPricingStepSeperatorTxt{
    float: left;
    color: $white;
    font-size: 1em;
}

.posPageSidebarOrderPricingStepVal{
    width: 45%;
    float: left;
    display: flex;
    justify-content: right;
}

.posPageSidebarOrderPricingStepValInner{
    float: left;
}

.posPageSidebarOrderPricingStepValIco{
    float: left;
    font-size: 0.6em;
    color: $yellow-primary;
    margin-top: 4px;
}

.posPageSidebarOrderPricingStepValTxt{
    float: left;
    font-size: 1em;
    color: $yellow-primary;
    margin-left: 7px;
}

.posPageSidebarOrderPricingStepLableTotalTxt{
    float: left;
    color: $white;
    font-size: 1.8em;
    text-transform: capitalize;
}

.posPageSidebarOrderPricingStepSeperatorTotalTxt{
    float: left;
    color: $white;
    font-size: 1em;
}

.posPageSidebarOrderPricingStepValTotalIco{
    float: left;
    font-size: 0.8em;
    color: $yellow-primary;
    margin-top: 11px;
}

.posPageSidebarOrderPricingStepValTotalTxt{
    float: left;
    font-size: 1.8em;
    color: $yellow-primary;
    margin-left: 7px;
}

.posPageSidebarOrderActions{
    width: $full-width;
    float: left;
    padding: 0px 110px 0px 50px;
    position: relative;
    margin-top: 20px;
}

.posPageSidebarOrderDiscountAction{
    width: 50px;
    height: 50px;
    border-radius: 20px;
    background-color: $yellow-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
}

.posPageSidebarOrderDiscountAction:hover{
    background-color: $yellow-darker;
}

.posPageSidebarOrderDiscountActionIco{
    font-size: 1.3em;
    color: $blue-dark;
    float: left;
}

.posPageSidebarOrderMakePaymentAction{
    width: $full-width;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.posPageSidebarOrderDiscountActionTxt{
    text-transform: uppercase;
    font-family: font("semibold");
    float: left;
    color: $blue-dark;
    width: $full-width;
    height: 50px;
    border-radius: 20px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.posPageSidebarOrderDiscountActionTxt:hover{
    background-color: $grey;
}

.posPageSidebarOrderMultiActions{
    width: 110px;
    float: left;
    position: absolute;
    right: 0px;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.posPageSidebarOrderPrintAction{
    width: 50px;
    height: 50px;
    border-radius: 20px;
    background-color: $yellow-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.posPageSidebarOrderPrintAction:hover{
    background-color: $yellow-darker;
}

.posPageSidebarOrderPrintActionTxt{
    font-size: 1.3em;
    color: $blue-dark;
    float: left;
}

.posPageSidebarOrderDiscardAction{
    width: 50px;
    height: 50px;
    border-radius: 20px;
    background-color: $yellow-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.posPageSidebarOrderDiscardAction:hover{
    background-color: $yellow-darker;
}

.posPageSidebarOrderDiscardActionTxt{
    font-size: 1.3em;
    color: $blue-dark;
    float: left;
}

.posPageContainer{
    width: $full-width;
    float: left;
    padding: 25px 0px;
}

.posPageContainerHeadings{
    width: $full-width;
    float: left;
    margin-bottom: 25px;
}

.posPageContainerHeadingTxt{
    width: $full-width;
    float: left;
    color: $white;
    font-size: 2em;
    font-family: font("semibold");
    text-transform: capitalize;
}

.posPageContainerSubHeadingTxt{
    width: $full-width;
    float: left;
    color: $white;
    font-size: 1em;
    font-family: font("regular");
    text-transform: capitalize;
    margin-top: 5px;
}

.posPageSection{
    width: $full-width;
    float: left;
    padding: 20px 30px 30px;
    background-color: $blue-dark;
    border-radius: 15px;
}

.posPageSectionHeadings{
    width: $full-width;
    float: left;
}

.posPageSectionHeadingTxt{
    width: $full-width;
    float: left;
    color: $white;
    font-size: 1.6em;
    font-family: font("semibold");
    text-transform: capitalize;
}

.posPageSectionSubHeadingTxt{
    width: $full-width;
    float: left;
    color: $white;
    font-size: 0.85em;
    font-family: font("regular");
    text-transform: capitalize;
    margin-top: 5px;
}

.posPageSectionContent{
    width: $full-width;
    float: left;
    margin-top: 25px;
}

.posPageFormMain{
    width: $full-width;
    float: left;
}

.posPageFormStep{
    width: $full-width;
    float: left;
    position: relative;
    margin-bottom: 20px;
}

.posPageFormStep:last-child{
    margin: 0px;
}

.posPageFormStepLable{
    width: $full-width;
    float: left;
}

.posPageFormStepLableTxt{
    float: left;
    text-transform: capitalize;
    font-size: 0.9em;
    font-family: font("regular");
    color: $yellow-primary;
}

.posPageFormStepInput{
    width: $full-width;
    float: left;
    margin-top: 10px;
}

.posPageFormStepInputTxt{
    width: $full-width;
    float: left;
    padding: 15px 20px;
    border: 2px dashed $dark-grey;
    border-radius: 10px;
    background: none;
    color: $white;
    font-size: 1em;
}

.posPageFormStepInputTxtarea{
    width: $full-width;
    float: left;
    padding: 15px 20px;
    border: 2px dashed $dark-grey;
    border-radius: 10px;
    background: none;
    color: $white;
    font-size: 1em;
    resize: none;
}

.posPageFormStepInputSelect{
    width: $full-width;
    float: left;
    padding: 15px 20px;
    border: 2px dashed $dark-grey;
    border-radius: 10px;
    background: none;
    color: $white;
    font-size: 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-transform: capitalize;
}

.posPageFormStepInputSelectOption{
    background-color: $blue-darker;
    border: 1px dashed $dark-grey;
    border-radius: 3px;
    color: $white;
    padding: 20px 0px;
}

.posPageFormStepSubmit{
    width: $full-width;
    float: left;
    padding: 10px 0px;
    background-color: $yellow-primary;
    border-radius: 5px;
    font-size: 1.1em;
    font-family: font('semibold');
    text-transform: uppercase;
    cursor: pointer;
}

.posPageFormStepSubmit:hover{
    background-color: $yellow-darker;
}

.posPopUp{
    width: $full-width;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.posPopUpOverlay{
    width: 100%;
    height: 100%;
    float: left;
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9;
}

.posPopUpMain{
    width: 450px;
    float: left;
    background-color: $blue-primary;
    z-index: 99;
    overflow: hidden;
    border-radius: 10px;
}

.posPopUpInner{
    width: 100%;
    float: left;
}

.posPopUpHeading{
    width: $full-width;
    float: left;
    padding: 20px 0px 17px 0px;
    border-bottom: 2px dashed $dark-grey;
    display: flex;
    justify-content: center;
}

.posPopUpHeadingTxt{
    float: left;
    color: $white;
    text-transform: uppercase;
    font-size: 1.4em;
    font-family: font("bold");
}

.posPopUpContent{
    width: $full-width;
    float: left;
}

.posPopUpContentMain{
    width: $full-width;
    float: left;
    max-height: 450px;
    overflow-x: auto;
    padding: 20px 0px;
}

.posPopUpContentInner{
    width: $full-width;
    float: left;
    padding: 0px 30px;
}

.posPopUpContentSizeSection{
    width: $full-width;
    float: left;
}

.posPopUpContentSizeSectionHeading{
    width: $full-width;
    float: left;
}

.posPopUpContentSizeSectionHeadingTxt{
    width: $full-width;
    float: left;
    padding-bottom: 5px;
    border-bottom: 1px solid $yellow-primary;
    color: $yellow-primary;
    text-transform: uppercase;
    font-size: 1.1em;
    font-family: font("semibold");
}

.posPopUpContentSizeSectionContent{
    width: $full-width;
    float: left;
    margin-top: 20px;
}

.posPopUpContentSizeSectionContentSingle{
    width: $full-width;
    float: left;
    margin-bottom: 15px;
}

.posPopUpContentSizeSectionContentSingle:last-child{
    margin: 0px;
}

.posPopUpContentSizeSectionContentSingleHeading{
    width: $full-width;
    float: left;
}

.posPopUpContentSizeSectionContentSingleHeadingTxt{
    color: $white;
    float: left;
    font-size: 1em;
    text-transform: capitalize;
    font-family: font("semibold");
}

.posPopUpContentSizeSectionContentSingleCrusts{
    width: $full-width;
    float: left;
    margin-top: 10px;
}

.posPopUpContentSizeSectionContentSingleCrustSingle{
    padding: 10px 20px;
    float: left;
    border-radius: 10px;
    border: 1px dashed $dark-grey;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-right: 15px;
    cursor: pointer;
}

.posPopUpContentSizeSectionContentSingleCrustSingle:last-child{
    margin: 0px;
}

.posPopUpContentSizeSectionContentSingleCrustSingle:hover{
    border: 1px solid $yellow-primary;
}

.posPopUpContentSizeSectionContentSingleCrustSingle:hover .posPopUpContentSizeSectionContentSingleCrustSingleTxt{
    color: $yellow-primary;
}

.posPopUpContentSizeSectionContentSingleCrustSingle:hover .posPopUpContentSizeSectionContentSingleCrustSingleSeperator{
    color: $yellow-primary;
}

.posPopUpContentSizeSectionContentSingleCrustSingle:hover .posPopUpContentSizeSectionContentSingleCrustSinglePrice .posPopUpContentSizeSectionContentSingleCrustSinglePriceIco{
    color: $yellow-primary;
}

.posPopUpContentSizeSectionContentSingleCrustSingle:hover .posPopUpContentSizeSectionContentSingleCrustSinglePrice .posPopUpContentSizeSectionContentSingleCrustSinglePriceTxt{
    color: $yellow-primary;
}

.posPopUpContentSizeSectionContentSingleCrustSingle.selected{
    border: 1px solid $yellow-primary;
    background-color: $yellow-primary;
}

.posPopUpContentSizeSectionContentSingleCrustSingle.selected .posPopUpContentSizeSectionContentSingleCrustSingleTxt{
    color: $blue-primary;
}

.posPopUpContentSizeSectionContentSingleCrustSingle.selected .posPopUpContentSizeSectionContentSingleCrustSingleSeperator{
    color: $blue-primary;
}

.posPopUpContentSizeSectionContentSingleCrustSingle.selected .posPopUpContentSizeSectionContentSingleCrustSinglePrice .posPopUpContentSizeSectionContentSingleCrustSinglePriceIco{
    color: $blue-primary;
}

.posPopUpContentSizeSectionContentSingleCrustSingle.selected .posPopUpContentSizeSectionContentSingleCrustSinglePrice .posPopUpContentSizeSectionContentSingleCrustSinglePriceTxt{
    color: $blue-primary;
}

.posPopUpContentSizeSectionContentSingleCrustSingleTxt{
    color: $white;
    font-size: 0.9em;
    text-transform: capitalize;
    float: left;
}

.posPopUpContentSizeSectionContentSingleCrustSingleSeperator{
    color: $white;
    font-size: 0.9em;
    text-transform: capitalize;
    float: left;
}

.posPopUpContentSizeSectionContentSingleCrustSinglePrice{
    float: left;
}

.posPopUpContentSizeSectionContentSingleCrustSinglePriceIco{
    float: left;
    font-size: 0.5em;
    color: $white;
    margin: 4px 5px 0px 0px;
}

.posPopUpContentSizeSectionContentSingleCrustSinglePriceTxt{
    float: left;
    color: $white;
    font-size: 0.9em;
    text-transform: capitalize;
}

.posPopUpContentVariantSection{
    width: $full-width;
    float: left;
    margin-top: 30px;
}

.posPopUpContentVariantSectionSingle{
    width: $full-width;
    float: left;
    margin-bottom: 15px;
}

.posPopUpContentVariantSectionSingle:last-child{
    margin: 0px;
}

.posPopUpContentVariantSectionHeading{
    width: $full-width;
    float: left;
}

.posPopUpContentVariantSectionHeadingTxt{
    width: $full-width;
    float: left;
    padding-bottom: 5px;
    border-bottom: 1px solid $yellow-primary;
    color: $yellow-primary;
    text-transform: uppercase;
    font-size: 1.1em;
    font-family: font("semibold");
}

.posPopUpContentVariantSectionContent{
    width: $full-width;
    float: left;
    margin-top: 20px;
}

.posPopUpContentVariantSectionContentSingle{
    padding: 10px 20px;
    float: left;
    border-radius: 10px;
    border: 1px dashed $dark-grey;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 0px 15px 15px 0px;
    cursor: pointer;
}

.posPopUpContentVariantSectionContentSingle:last-child{
    margin-right: 0px;
}

.posPopUpContentVariantSectionContentSingle:hover{
    border: 1px solid $yellow-primary;
}

.posPopUpContentVariantSectionContentSingle:hover .posPopUpContentVariantSectionContentSingleTxt{
    color: $yellow-primary;
}

.posPopUpContentVariantSectionContentSingle:hover .posPopUpContentVariantSectionContentSingleSeperator{
    color: $yellow-primary;
}

.posPopUpContentVariantSectionContentSingle:hover .posPopUpContentVariantSectionContentSinglePrice .posPopUpContentVariantSectionContentSinglePriceIco{
    color: $yellow-primary;
}

.posPopUpContentVariantSectionContentSingle:hover .posPopUpContentVariantSectionContentSinglePrice .posPopUpContentVariantSectionContentSinglePriceTxt{
    color: $yellow-primary;
}

.posPopUpContentVariantSectionContentSingle.selected{
    border: 1px solid $yellow-primary;
    background-color: $yellow-primary;
}

.posPopUpContentVariantSectionContentSingle.selected .posPopUpContentVariantSectionContentSingleTxt{
    color: $blue-primary;
}

.posPopUpContentVariantSectionContentSingle.selected .posPopUpContentVariantSectionContentSingleSeperator{
    color: $blue-primary;
}

.posPopUpContentVariantSectionContentSingle.selected .posPopUpContentVariantSectionContentSinglePrice .posPopUpContentVariantSectionContentSinglePriceIco{
    color: $blue-primary;
}

.posPopUpContentVariantSectionContentSingle.selected .posPopUpContentVariantSectionContentSinglePrice .posPopUpContentVariantSectionContentSinglePriceTxt{
    color: $blue-primary;
}

.posPopUpContentVariantSectionContentSingleTxt{
    color: $white;
    font-size: 0.9em;
    text-transform: capitalize;
    float: left;
}

.posPopUpContentVariantSectionContentSingleSeperator{
    color: $white;
    font-size: 0.9em;
    text-transform: capitalize;
    float: left;
}

.posPopUpContentVariantSectionContentSinglePrice{
    float: left;
}

.posPopUpContentVariantSectionContentSinglePriceIco{
    float: left;
    font-size: 0.5em;
    color: $white;
    margin: 4px 5px 0px 0px;
}

.posPopUpContentVariantSectionContentSinglePriceTxt{
    float: left;
    color: $white;
    font-size: 0.9em;
    text-transform: capitalize;
}

.posPopUpContentActions{
    width: $full-width;
    float: left;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    background-color: $blue-darker;
}

.posPopUpContentActionsInner{
    float: left;
    display: flex;
    gap: 10px;
    flex-direction: row;
}

.posPopUpContentActionSingle{
    background-color: $yellow-primary;
    border-radius: 5px;
    height: 40px;
    padding: 0px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: $blue-darker;
    font-size: 1em;
    font-family: font("semibold");
    cursor: pointer;
}

.posPopUpContentActionSingle:hover{
    background-color: $yellow-darker;
}

.deliverOrderPopUpDistance{
    width: $full-width;
    float: left;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.deliverOrderPopUpDistanceInner{
    float: left;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.deliverOrderPopUpDistanceLable{
    float: left;
    font-size: 1em;
    text-transform: capitalize;
    color: $white;
    font-family: font("semibold");
}

.deliverOrderPopUpDistanceStat{
    float: left;
    color: $yellow-primary;
    font-size: 1em;
    font-family: font("semibold");
}

.loginPageContentFormStepError{width: $full-width;float: left;margin-top: 10px;}
.loginPageContentFormStepErrorTxt{float: left;color: $red-primary;text-transform: capitalize;font-size: 0.9em;font-family: font("regular");}

.loginPageContentFormStepPayModes{
    width: $full-width;
    float: left;
    margin-top: 10px;
}



.posPopUpContentPaymentModeSingle{
    padding: 10px 20px;
    float: left;
    border-radius: 10px;
    border: 1px dashed $dark-grey;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-right: 15px;
    cursor: pointer;
}

.posPopUpContentPaymentModeSingle:last-child{
    margin: 0px;
}

.posPopUpContentPaymentModeSingle:hover{
    border: 1px solid $yellow-primary;
}

.posPopUpContentPaymentModeSingle:hover .posPopUpContentPaymentModeSingleTxt{
    color: $yellow-primary;
}

.posPopUpContentPaymentModeSingle:hover .posPopUpContentPaymentModeSinglePrice .posPopUpContentPaymentModeSinglePriceTxt{
    color: $yellow-primary;
}

.posPopUpContentPaymentModeSingle.selected{
    border: 1px solid $yellow-primary;
    background-color: $yellow-primary;
}

.posPopUpContentPaymentModeSingle.selected .posPopUpContentPaymentModeSingleTxt{
    color: $blue-primary;
}

.posPopUpContentPaymentModeSingleTxt{
    color: $white;
    font-size: 0.9em;
    text-transform: capitalize;
    float: left;
}

.posPageContainerStats{width: $full-width;float: left;display: flex;flex-direction: row;gap: 30px;}
.posPageContainerStatSingle{float: left;padding: 20px 30px;width: 25%;background-color: $blue-darker;border-radius: 20px;}
.posPageContainerStatSingle:nth-child(1){background-color: #c02329;}
.posPageContainerStatSingle:nth-child(2){background-color: #278855;}
.posPageContainerStatSingle:nth-child(3){background-color: #0a1f3e;}
.posPageContainerStatSingle:nth-child(4){background-color: #9c9a2d;}
.posPageContainerStatSingleStat{width: $full-width;float: left;}
.posPageContainerStatSingleStatTxt{float: right;color: $white;font-size: 3em;font-family: font("bold");}
.posPageContainerStatSingleStatLable{width: $full-width;float: left;}
.posPageContainerStatSingleStatLableTxt{float: right;color: $white;font-size: 1em;font-family: font("semibold");text-transform: capitalize;max-width: 100%;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}



.actionButton {
    float: left;
    padding: 0 40px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
    font-family: font("semibold");
    text-transform: capitalize;
  }
  .actionButton.primary {
    background: $blue-primary;
    color: $white;
  }
  .actionButton.primary:hover {
    background: $blue-darker;
  }
  .actionButton.success {
    background: #278855;
    color: $white;
  }
  .actionButton.success:hover {
    background: #17683d;
  }
  .actionButton.danger {
    background: #c84114;
    color: $white;
  }
  .actionButton.danger:hover {
    background: #952905;
  }
  
  
  .iconButton {
    float: left;
    padding: 0 15px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
    font-family: font("semibold");
    text-transform: capitalize;
  }
  .iconButton.primary {
    background: $blue-primary;
    color: $white;
  }
  .iconButton.primary:hover {
    background: $blue-darker;
  }
  .iconButton.success {
    background: #278855;
    color: $white;
  }
  .iconButton.success:hover {
    background: #17683d;
  }
  .iconButton.danger {
    background: #c84114;
    color: $white;
  }
  .iconButton.danger:hover {
    background: #952905;
  }
  
  .tableButton {
    float: left;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
    font-family: font("semibold");
    text-transform: capitalize;
  }
  .tableButton.primary {
    background: $blue-primary;
    color: $white;
  }
  .tableButton.primary:hover {
    background: $blue-darker;
  }
  .tableButton.success {
    background: #278855;
    color: $white;
  }
  .tableButton.success:hover {
    background: #17683d;
  }
  .tableButton.danger {
    background: #c84114;
    color: $white;
  }
  .tableButton.danger:hover {
    background: #952905;
  }
  
  .tableHeadingItem {
    height: 45px;
    border-right: 1px solid $blue-primary;
  }
  .tableHeadingItem.col1 {
    min-width: 70px;
  }
  .tableHeadingItem.col2 {
    min-width: 150px;
  }
  .tableHeadingItem.col3 {
    min-width: 230px;
  }
  .tableHeadingItem.col4 {
    min-width: 320px;
  }
  .tableHeadingItem:last-child {
    border: 0px;
  }
  
  .tableHeadingTxt {
    text-transform: uppercase;
    color: $text-dark;
    font-family: font("bold");
    font-size: 1em;
  }

.tableRow {
    width: $full-width;
    float: left;
    display: flex;
    flex-direction: row;
    background: #ecf0fb;
    border-bottom: 1px solid $text-dark;
  }
  .tableRow:nth-child(2n) {
    background: $white;
  }
  .tableRow:last-child {
    border-bottom: 0px;
  }
  
  .tableRowItem {
    height: 45px;
    border-right: 1px solid $blue-primary;
  }
  .tableRowItem.col1 {
    min-width: 70px;
  }
  .tableRowItem.col2 {
    min-width: 150px;
  }
  .tableRowItem.col3 {
    min-width: 230px;
  }
  .tableRowItem.col4 {
    min-width: 320px;
  }
  .tableRowItem:last-child {
    border: 0px;
  }
  
  .tableContentTxt {
    color: $text-dark;
    font-family: font("semibold");
    font-size: 0.95em;
    text-transform: capitalize;
  }
  .tableRowItemImage {
    width: $full-width;
    float: left;
  }
  .tableRowItemImageMain {
    width: 35px;
    height: 35px;
    float: left;
    border: 1px dashed $blue-primary;
    border-radius: 3px;
    overflow: hidden;
    padding: 3px;
  }
  .tableRowItemImg {
    max-width: $full-width;
    height: 100%;
    float: left;
  }
  
  .tableRowItemIcon {
    width: $full-width;
    float: left;
  }
  .tableRowItemIcon {
    font-size: 1.2em;
    color: $blue-primary;
    float: left;
  }

  .pageHeadingTxt {
    font-size: 1.8em;
    float: left;
    font-family: font("semibold");
    text-transform: capitalize;
  }
  .pageSubHeadingTxt {
    float: left;
    color: $text-dark;
    font-size: 1em;
    font-family: font("semibold");
  }

  .sectionHeadingTxt {
    width: $full-width;
    float: left;
    color: $full-width;
    font-family: font("semibold");
    font-size: 1.6em;
    text-transform: capitalize;
  }
  .sectionDescriptionTxt {
    width: $full-width;
    float: left;
    color: $text-dark;
    font-family: font("semibold");
    font-size: 0.9em;
    text-transform: capitalize;
  }

  
// pagination starts
.dashboardContentTablePagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .dashboardContentTablePaginationInner {
    float: right;
  }
  .dashboardContentTablePaginationAction {
    float: left;
    margin-right: 15px;
  }
  .dashboardContentTablePaginationActionTxt:hover,
  .active > .dashboardContentTablePaginationActionTxt {
    background: #000;
  }
  .dashboardContentTablePaginationAction:last-child {
    margin: 0px;
  }
  .dashboardContentTablePaginationActionTxt {
    width: 100%;
    padding: 0px 30px;
    height: 30px;
    float: left;
    font-family: font("semibold");
    font-size: 0.85em;
    text-align: center;
    background: #0a1f3e;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #fff;
  }
  .dashboardContentTablePaginationPages {
    float: left;
    margin: 0px 15px 0px 0px;
  }
  .dashboardContentTablePaginationPageSingle {
    float: left;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #0a1f3e;
    margin-right: 15px;
  }
  .dashboardContentTablePaginationPageSingle:last-child {
    margin: 0px;
  }
  .dashboardContentTablePaginationPageSingle:hover {
    background: #0a1f3e;
    cursor: pointer;
  }
  
  .dashboardContentTablePaginationPageSingle.active {
    background: #0a1f3e;
    cursor: pointer;
  }
  
  .dashboardContentTablePaginationPageSingle:hover
    .dashboardContentTablePaginationPageSingleTxt {
    color: #fff;
  }
  
  .dashboardContentTablePaginationPageSingle.active
    .dashboardContentTablePaginationPageSingleTxt {
    color: #fff;
  }
  
  .dashboardContentTablePaginationPageSingleTxt {
    width: 100%;
    float: left;
    font-family: font("semibold");
    font-size: 0.85em;
    text-align: center;
    text-transform: uppercase;
    color: #0a1f3e;
  }
  // pagination ends

.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hCenter {
    display: flex;
    justify-content: center;
  }
  .vCenter {
    display: flex;
    align-items: center;
  }
  .txtCenter {
    text-align: center;
  }
  
  .capitalize {
    text-transform: capitalize;
  }
  
  .fullWidth {
    width: $full-width;
  }
  
  .textPrimary {
    color: $blue-primary;
  }
  .padding {
    padding: $padding;
  }
  .paddingTop {
    padding-top: $padding;
  }
  .paddingLeft {
    padding-left: $padding;
  }
  .paddingRight {
    padding-right: $padding;
  }
  .paddingBottom {
    padding-bottom: $padding;
  }
  
  .emailTxt {
    text-transform: none !important;
  }
  
  .marginTop10{margin-top: 10px !important;}
  
  .pageHeadingTxt {
    font-size: 1.8em;
    float: left;
    font-family: font("semibold");
    text-transform: capitalize;
  }
  .pageSubHeadingTxt {
    float: left;
    color: $text-dark;
    font-size: 1em;
    font-family: font("semibold");
  }

  .sectionMarginTop{width: $full-width;float: left;margin-top: 0px;height: 30px;}

  .plainContainer {
    float: left;
    position: relative;
  }
  
  .splitContainer {
    float: left;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 15px;
  }
  
  .splitContainer .formStep{margin: 0px !important;}

  
.detailSection{width: $full-width;float: left;border: 2px solid $blue-primary;}
.detailSectionHeading{width: $full-width;background: $blue-primary;float: left;height: 45px;padding: 0px 10px 2px;display: flex;justify-content: space-between;align-items: center;}
.detailSectionHeadingLable{float: left;}
.detailSectionHeadingLableTxt{float: left;text-transform: capitalize;color: $white;font-size: 1em;font-family: font("semibold");text-indent: 5px;}

.detailSectionHeadingActions{float: left;display: flex;flex-direction: row;gap: 10px;}
.detailSectionHeadingButton{float: left;height: 30px;border-radius: 5px;cursor: pointer;font-size: 0.9em;font-family: font("semibold");color: $blue-primary;text-transform: capitalize;padding: 0px 20px;background: $white;}
.detailSectionHeadingButton:hover{background: $grey;}

.detailSectionContent{width: $full-width;float: left;}
.detailSectionContent.hasPadding{padding: 15px 15px;}

.detailSectionRow{width: $full-width;float: left;margin-bottom: 15px;display: flex;flex-direction: row;}
.detailSectionRow:last-child{margin: 0px;}
.detailSectionRowLable{flex: 1;padding-right: 50px;float: left;}
.detailSectionRowLableTxt{float: left;font-family: font("semibold");color: $blue-primary;font-size: 0.9em;text-transform: capitalize;margin-top: 4px;}
.detailSectionRowSeperator{width: 10px;float: left;display: flex;justify-content: center;}
.detailSectionRowSeperatorTxt{float: left;font-family: font("semibold");color: $blue-primary;font-size: 0.9em;text-transform: capitalize;margin-top: 2px;}
.detailSectionRowContent{flex: 4;float: left;padding-left: 20px;}
.detailSectionRowContentTxt{float: left;font-family: font("semibold");color: $blue-primary;font-size: 0.9em;text-transform: capitalize;text-align: justify;line-height: 1.8em;}
.detailSectionRowContentTxt:last-child .detailSectionRowContentTxtLoopComa{display: none;}
.detailSectionRowContentImage{float: left;max-width: 100px;max-height: 100px;}


.noDataFound{width: $full-width;float: left;display: flex;justify-content: center;}
.noDataFoundMain{padding: 40px 80px;border: 2px dashed $blue-primary;border-radius: 30px;}
.noDataFoundInner{width: $full-width;float: left;}
.noDataFoundVisual{width: $full-width;display: flex;justify-content: center;}
.noDataFoundIcon{float: left;}
.noDataFoundIconInner{float: left;}
.noDataFoundIco{color: $blue-primary;font-size: 4em;}
.noDataFoundContent{width: $full-width;float: left;margin-top: 20px;display: flex;justify-content: center;flex-direction: column;}
.noDataFoundContentHeading{width: $full-width;display: flex;justify-content: center;}
.noDataFoundContentHeadingTxt{float: left;font-size: 1.4em;color: $blue-primary;text-transform: capitalize;font-family: font("semibold");}
.noDataFoundContentDescription{width: $full-width;float: left;margin-top: 5px;justify-content: center;}
.noDataFoundContentDescriptionTxt{width: $full-width;float: left;text-align: center;font-size: 0.85em;}
.noDataFoundAction{width: $full-width;float: left;margin-top: 20px;display: flex;justify-content: center;}
.noDataFoundActionInner{float: left;display: flex;flex-direction: row;}
.noDataFoundActionSingle{float: left;margin-right: 20px;}
.noDataFoundActionSingle:last-child{margin: 0px;}